// Constants for HTTP status codes
const HttpStatusCodes = {
  OK: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403, // Add more status codes as needed
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
};

export default HttpStatusCodes;
