// api
// const url = 'http://36.92.181.10:9981/mrsbe';
const url = 'https://mrsbe.salvaco.id';
// Authentication
const apiLogin = '/login';
const apiDecodeToken = '/login/decode';
const apiOddoLogin = '/login/oddo_login';
const apiLogout = '/login/logout';
const apiVerifyAccount = '/distributor/verify/';
// Product
const apiPostProduct = '/product';

// Distributor
const apiPostDistributor = '/distributor';
const apiLoginDistributor = '/distributor/login';
const apiReqOtp = '/distributor/req_otp';
const apiForgot = '/distributor/forgot';
const apiGetStateDistributor = '/distributor/state';
const apiGetDistributor = '/distributor/get/';
const apiGetDecodeTokenDistributor = '/distributor/decode_token';
const apiDistributorLogout = '/distributor/logout';
const apiCancelOrderbyDistributor = '/purchase/delete_by_distributor/';
const apiGetState = '/distributor/get_state/';
const apiDistributorAdd = '/distributor/add';
const apiDistributorList = '/distributor/distributor_login_list';
const apiDistributorSync = '/distributor/sync_username/';
const apiDistributorGetEmail = '/distributor/get_distributor_by_email';
// Purchase
const apiPostPurchase = '/purchase';
const apiPostAddPurchase = '/purchase/add';
const apiPostUpdatePurchase = '/purchase/update/';
const apiPostAddItem = '/purchase/add_item/';
const apiPostEditItem = '/purchase/edit_item/';
const apiGetPurchaseItem = '/purchase/get/';
const apiGetConfirmationPurchase = '/purchase/confirmation/';
const apiGetDeletePurchase = '/purchase/delete/';
const apiGetDeleteItem = '/purchase/delete_item/';
const apiGetPostingPO = '/purchase/posting/';
const apiGetReconsilitation = '/purchase/reconsiliation/';
const apiPurchaseReport = '/purchase/report';
const apiPurchaseProductReport = '/purchase/report_product';
const apiPurchaseShippingChange = '/purchase/shipping_changed';
const apiPurchaseSyncDiscount = '/purchase/sync_discount/';
// posting purchase
const apiPostPurchaseFinanceStatusbyId = '/purchase/finance_status/';
const apiGetPurchasePostingRuleSSObyId = '/purchase/posting1/';
const apiGetPurchasePostingManagerbyId = '/purchase/posting2/';
const apiGetPurchasePostingCSObyId = '/purchase/posting3/';

// Payment
const apiPostPayment = '/payment';

// Role
const apiGetRole = '/roles';
const apiPostAddRole = '/roles/add';
const apiDeleteRole = '/roles/delete/';
const apiUpdateRole = '/roles/update/';
const apiGetRolebyId = '/roles/get/';

// Log
const apiPostLog = '/log';

// Ratio
const apiGetRatio = '/ratio/get/';
const apiPostRatio = '/ratio';
const apiAddRatio = '/ratio/add';
const apiUpdateRatio = '/ratio/update/';

// Subsidy
const apiPostSubsidy = '/subsidy';
const apiAddSubsidy = '/subsidy/add';
const apiDeleteSubsidy = '/subsidy/delete';
const apiGetSubsidy = '/subsidy/get/';

// Limit Order
const apiPostLimitOrder = '/limitorder';
const apiPostAddLimitOrder = '/limitorder/add';
const apiDeleteLimitOrder = '/limitorder/delete/';
const apiGetLimitOrder = '/limitorder/get/';

// component
const apiGetComponent = '/component';
const apiGetComponentbyId = '/component/get/';
const apiGetComponentbyName = '/component/get_by_name/';
const apiPostComponentAdd = '/component/add';
const apiPostComponentUpdate = '/component/update/';
const apiGetComponentDelete = '/component/delete/';
const apiGetComponentClosing = '/component/closing/';
const apiGetComponentReset = '/component/reset';

// configuration admin
const apiPostAdmin = '/admin';
const apiPostAdminAdd = '/admin/add';
const apiGetAdminById = '/admin/get/';
const apiPostAdminUpdate = '/admin/update/';
const apiGetAdminDelete = '/admin/delete/';

// distributor client
const apiGetListUser = '/distributor/list_user';
const apiGetUser = '/distributor/get_user';
const apiPostEditUser = '/distributor/edit_user/';
const apiGetDeleteUser = '/distributor/delete_user/';
const apiPostAddUser = '/distributor/add_user';

export {
  url,
  apiLogin,
  apiLoginDistributor,
  apiReqOtp,
  apiForgot,
  apiLogout,
  apiDecodeToken,
  apiOddoLogin,
  apiPostProduct,
  apiPostDistributor,
  apiPostPurchase,
  apiGetPurchaseItem,
  apiPostAddPurchase,
  apiPostAddItem,
  apiGetDeleteItem,
  apiPostEditItem,
  apiGetDeletePurchase,
  apiGetConfirmationPurchase,
  apiGetDistributor,
  apiGetDecodeTokenDistributor,
  apiPostPayment,
  apiDistributorLogout,
  apiPostUpdatePurchase,
  apiCancelOrderbyDistributor,
  apiGetRole,
  apiGetRolebyId,
  apiPostAddRole,
  apiDeleteRole,
  apiUpdateRole,
  apiPostLog,
  apiGetState,
  apiGetRatio,
  apiPostRatio,
  apiAddRatio,
  apiUpdateRatio,
  apiGetStateDistributor,
  apiGetPostingPO,
  apiPostSubsidy,
  apiAddSubsidy,
  apiDeleteSubsidy,
  apiGetSubsidy,
  apiPostLimitOrder,
  apiPostAddLimitOrder,
  apiGetLimitOrder,
  apiDeleteLimitOrder,
  apiGetDeleteUser,
  apiGetListUser,
  apiGetUser,
  apiPostAddUser,
  apiPostEditUser,
  apiGetComponent,
  apiGetComponentbyId,
  apiGetComponentbyName,
  apiPostComponentAdd,
  apiPostComponentUpdate,
  apiGetComponentDelete,
  apiGetComponentClosing,
  apiGetComponentReset,
  apiPostAdmin,
  apiGetAdminById,
  apiGetAdminDelete,
  apiPostAdminAdd,
  apiPostAdminUpdate,
  apiGetPurchasePostingCSObyId,
  apiGetPurchasePostingManagerbyId,
  apiGetPurchasePostingRuleSSObyId,
  apiPostPurchaseFinanceStatusbyId,
  apiDistributorAdd,
  apiGetReconsilitation,
  apiPurchaseReport,
  apiPurchaseProductReport,
  apiVerifyAccount,
  apiPurchaseShippingChange,
  apiDistributorList,
  apiDistributorSync,
  apiDistributorGetEmail,
  apiPurchaseSyncDiscount,
};
