import React, { Component } from 'react';
import axios from 'axios';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Swal from 'sweetalert2';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlassChart } from '@fortawesome/free-solid-svg-icons';
import {
  apiDecodeToken,
  apiLogout,
  url,
  apiGetDecodeTokenDistributor,
  apiDistributorLogout,
} from '../api/services';

class Sidebar extends Component {
  state = {
    collapseStatus: {
      appsMenuOpen: false,
      catalogsMenuOpen: false,
      customerMenuOpen: false,
      promoMenuOpen: false,
      userMenuOpen: false,
      settingMenuOpen: false,
      reportMenuOpen: false,
      orderMenuOpen: false,
    },
    isAdmin: false,
    isDistributor: false,
    distributorMenuOpen: false,
    nameDistributor: '',
    nameAdmin: '',
    roleAdmin: '',
  };

  toggleMenuState(menuState) {
    this.setState((prevState) => ({
      [menuState]: !prevState[menuState],
    }));
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    const token = localStorage.getItem('token');
    const tokenDistributor = localStorage.getItem('tokenDistributor');

    if (token) {
      const config = {
        method: 'get',
        url: `${url + apiDecodeToken}`,
        headers: {
          'Content-Type': 'application/json',
          'X-auth-token': `${token}`,
        },
      };

      axios
        .request(config)
        .then((response) => {
          const decodedToken = response.data;
          if (
            decodedToken.content.role === 'admin' ||
            'sso' ||
            'finance' ||
            'rsm' ||
            'nkam' ||
            'manager'
          ) {
            this.setState({
              isAdmin: true,
              isDistributor: false,
              nameAdmin: decodedToken.content.username,
              roleAdmin: decodedToken.content.role,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    if (tokenDistributor) {
      let configDistributor = {
        method: 'get',
        url: `${url + apiGetDecodeTokenDistributor}`,
        headers: {
          'X-Auth-Token': `${tokenDistributor}`,
        },
      };

      axios
        .request(configDistributor)
        .then((response) => {
          const decodedTokenDistributor = response.data.content;
          // if (decodedTokenDistributor.role === 'distributor') {
          this.setState({
            isDistributor: true,
            isAdmin: false,
            nameDistributor: decodedTokenDistributor.username,
          });
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    }

    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: '/apps', state: 'appsMenuOpen' },
      { path: '/order', state: 'orderMenuOpen' },
      { path: '/catalogue-product/list-product', state: 'catalogsMenuOpen' },
      { path: '/customer', state: 'customerMenuOpen' },
      { path: '/promo', state: 'promoMenuOpen' },
      { path: '/user', state: 'userMenuOpen' },
      { path: '/payment-config', state: 'paymentMenuOpen' },
      { path: '/manage-discount', state: 'manageDiscountMenuOpen' },
      { path: '/account', state: 'accountMenuOpen' },
      { path: '/setting', state: 'settingMenuOpen' },
      { path: '/report', state: 'reportMenuOpen' },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }
  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }
  componentDidMount() {
    this.onRouteChanged();

    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {
      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

  handleLogout = (token, logoutEndpoint, redirectPath) => {
    if (!token) {
      // If token is missing, redirect to the login page
      this.props.history.push(redirectPath);
      return;
    }

    Swal.fire({
      title: 'Logout',
      text: 'Are You Sure Want to Logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198ae3', // Change confirmation button color
      cancelButtonColor: '#ff0000', // Change cancel button color
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          method: 'get',
          url: `${url + logoutEndpoint}`,
          headers: {
            'Content-Type': 'application/json',
            'X-auth-token': `${token}`,
          },
        };

        axios
          .request(config)
          .then((response) => {
            // Remove token from localStorage
            localStorage.removeItem(token);

            // Redirect to the specified path
            this.props.history.push(redirectPath);
          })
          .catch((error) => {
            console.log(error);
            // Handle errors or other actions as needed
          });
      }
    });
  };

  render() {
    const { isAdmin, isDistributor, nameDistributor, nameAdmin, roleAdmin } =
      this.state;
    return (
      <nav className='sidebar sidebar-offcanvas' id='sidebar'>
        <ul className='nav'>
          {isAdmin && (
            <>
              <li className='nav-item nav-profile'>
                <Link to='/dashboard/Report' className='nav-link'>
                  <div className='nav-profile-image'>
                    <img
                      src={require('../../assets/images/logo.png')}
                      alt='profile'
                    />
                    <span className='login-status online'></span>{' '}
                    {/* change to offline or busy as needed */}
                  </div>
                  <div className='nav-profile-text'>
                    <span className='font-weight-bold mb-2'>
                      <Trans>{roleAdmin.toUpperCase()}</Trans>
                    </span>
                    <span className='text-primary font-weight-bold'>
                      <Trans>{nameAdmin.toUpperCase()}</Trans>
                    </span>
                  </div>
                </Link>
              </li>
              {/* Dashboard */}
              <li
                className={
                  this.isPathActive('/dashboard')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link className='nav-link' to='/dashboard'>
                  <span className='menu-title'>
                    <Trans>Dashboard</Trans>
                  </span>
                  <i
                    className='mdi mdi-home menu-icon'
                    style={{ scale: '1.8' }}></i>
                </Link>
              </li>
              {/* Order */}
              {roleAdmin === 'cso' ? (
                <li
                  className={
                    this.isPathActive('/order') ? 'nav-item active' : 'nav-item'
                  }>
                  <div
                    className={
                      this.state.orderMenuOpen
                        ? 'nav-link menu-expanded'
                        : 'nav-link'
                    }
                    onClick={() => this.toggleMenuState('orderMenuOpen')}
                    data-toggle='collapse'>
                    <span className='menu-title'>
                      <Trans>Order</Trans>
                    </span>
                    <i
                      className='menu-arrow'
                      style={{ scale: '1.8', marginRight: '10px' }}
                    />
                    <i
                      className='mdi mdi-cart menu-icon'
                      style={{ scale: '1.8' }}
                    />
                  </div>
                  <Collapse in={this.state.orderMenuOpen}>
                    <ul className='nav flex-column sub-menu'>
                      <li className='nav-item'>
                        {' '}
                        <Link
                          className={
                            this.isPathActive('/order/list-purchase')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/order/list-purchase'>
                          <span className='menu-subtitle'>
                            <Trans>order</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/order/shipping-change')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/order/shipping-change'>
                          <span className='menu-subtitle'>
                            <Trans>Synchronize Shipping Date</Trans>
                          </span>
                        </Link>
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : (
                <li
                  className={
                    this.isPathActive('/order') ? 'nav-item active' : 'nav-item'
                  }>
                  <Link className='nav-link' to='/order/list-purchase'>
                    <span className='menu-title'>
                      <Trans>Order</Trans>
                    </span>
                    <i
                      className='mdi mdi-cart menu-icon'
                      style={{ scale: '1.8' }}
                    />
                  </Link>
                </li>
              )}

              {/*   Products */}
              <li
                className={
                  this.isPathActive('/catalogue-product/list-product')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <div
                  className={
                    this.state.catalogsMenuOpen
                      ? 'nav-link menu-expanded'
                      : 'nav-link'
                  }
                  onClick={() => this.toggleMenuState('catalogsMenuOpen')}
                  data-toggle='collapse'>
                  <span className='menu-title'>
                    <Trans>Product Catalog</Trans>
                  </span>
                  <i
                    className='menu-arrow'
                    style={{ scale: '1.8', marginRight: '10px' }}
                  />
                  <i
                    className='mdi mdi-clipboard-text menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </div>
                <Collapse in={this.state.catalogsMenuOpen}>
                  <ul className='nav flex-column sub-menu'>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          this.isPathActive('/catalogue-product/list-product')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/catalogue-product/list-product'>
                        <span className='menu-subtitle'>
                          <Trans>Product List</Trans>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* Customer */}
              <li
                className={
                  this.isPathActive('/customer')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <div
                  className={
                    this.state.customerMenuOpen
                      ? 'nav-link menu-expanded'
                      : 'nav-link'
                  }
                  onClick={() => this.toggleMenuState('customerMenuOpen')}
                  data-toggle='collapse'>
                  <span className='menu-title'>
                    <Trans>Distributor</Trans>
                  </span>
                  <i
                    className='menu-arrow'
                    style={{ scale: '1.8', marginRight: '10px' }}
                  />
                  <i
                    className='mdi mdi-store menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </div>
                <Collapse in={this.state.customerMenuOpen}>
                  <ul className='nav flex-column sub-menu'>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          this.isPathActive('/customer/list-distributor')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/customer/list-distributor'>
                        <span className='menu-subtitle'>
                          <Trans>Distributor List</Trans>
                        </span>
                      </Link>
                      {roleAdmin === 'admin' && (
                        <Link
                          className={
                            this.isPathActive(
                              '/customer/list-login-distributor'
                            )
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/customer/list-login-distributor'>
                          <span className='menu-subtitle'>
                            <Trans>Distributor List Login</Trans>
                          </span>
                        </Link>
                      )}
                      {/* <Link
                        className={
                          this.isPathActive('/customer/list-outlet')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/customer/list-outlet'>
                        <span className='menu-subtitle'>
                          <Trans>Outlet List</Trans>
                        </span>
                      </Link>
                      <Link
                        className={
                          this.isPathActive('/customer/list-outlet')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/customer/list-outlet'>
                        <span className='menu-subtitle'>
                          <Trans>Sales List</Trans>
                        </span>
                      </Link> */}
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* Payment Config */}
              <li
                className={
                  this.isPathActive('/payment-configuration')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link className='nav-link' to='/payment-configuration'>
                  <span className='menu-title'>
                    <Trans>Payment Configuration</Trans>
                  </span>
                  <i
                    className='mdi mdi-credit-card menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </Link>
              </li>
              {/* Discount Management */}
              <li
                className={
                  this.isPathActive('/manage-discount')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link className='nav-link' to='/manage-discount'>
                  <span className='menu-title'>
                    <Trans>Discount Management</Trans>
                  </span>
                  <i
                    className='mdi mdi-sale menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </Link>
              </li>
              {/* Promo */}
              <li
                className={
                  this.isPathActive('/promo') ? 'nav-item active' : 'nav-item'
                }>
                <div
                  className={
                    this.state.promoMenuOpen
                      ? 'nav-link menu-expanded'
                      : 'nav-link'
                  }
                  onClick={() => this.toggleMenuState('promoMenuOpen')}
                  data-toggle='collapse'>
                  <span className='menu-title'>
                    <Trans>Promo</Trans>
                  </span>
                  <i
                    className='menu-arrow'
                    style={{ scale: '1.8', marginRight: '10px' }}
                  />
                  <i
                    className=' mdi mdi-tag-text-outline menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </div>
                <Collapse in={this.state.promoMenuOpen}>
                  <ul className='nav flex-column sub-menu'>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          this.isPathActive('/promo/voucher')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/promo/voucher'>
                        <span className='menu-subtitle'>
                          <Trans>Promo Vouchers</Trans>
                        </span>
                      </Link>
                      <Link
                        className={
                          this.isPathActive('/promo/banner')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/promo/banner'>
                        <span className='menu-subtitle'>
                          <Trans>Promo Banners</Trans>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>
              {/* report */}
              {roleAdmin === 'cso' || roleAdmin === 'sso' ? (
                <li
                  className={
                    this.isPathActive('/report')
                      ? 'nav-item active'
                      : 'nav-item'
                  }>
                  <div
                    className={
                      this.state.reportMenuOpen
                        ? 'nav-link menu-expanded'
                        : 'nav-link'
                    }
                    onClick={() => this.toggleMenuState('reportMenuOpen')}
                    data-toggle='collapse'>
                    <span className='menu-title'>
                      <Trans>Report</Trans>
                    </span>
                    <i
                      className='menu-arrow'
                      style={{ scale: '1.8', marginRight: '10px' }}
                    />
                    <FontAwesomeIcon
                      icon={faMagnifyingGlassChart}
                      style={{
                        scale: '1.8',
                        color: 'rgba(187, 168, 191, 0.9607843137)',
                      }}
                    />
                  </div>
                  <Collapse in={this.state.reportMenuOpen}>
                    <ul className='nav flex-column sub-menu'>
                      <li className='nav-item'>
                        {' '}
                        <Link
                          className={
                            this.isPathActive('/report/report-order')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/report/report-order'>
                          <span className='menu-subtitle'>
                            <Trans>Report Order</Trans>
                          </span>
                        </Link>
                        {/* <Link
                          className={
                            this.isPathActive('/report/report-chart')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/report/report-chart'>
                          <span className='menu-subtitle'>
                            <Trans>Report Chart</Trans>
                          </span>
                        </Link> */}
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : (
                <></>
              )}

              <div
                className='border-bottom'
                style={{
                  margin: '0 1.25rem',
                }}
              />
              {/* Global config */}
              {roleAdmin === 'admin' ? (
                <li
                  className={
                    this.isPathActive('/user') ? 'nav-item active' : 'nav-item'
                  }>
                  <div
                    className={
                      this.state.userMenuOpen
                        ? 'nav-link menu-expanded'
                        : 'nav-link'
                    }
                    onClick={() => this.toggleMenuState('userMenuOpen')}
                    data-toggle='collapse'>
                    <span className='menu-title'>
                      <Trans>Configuration</Trans>
                    </span>
                    <i
                      className='menu-arrow'
                      style={{ scale: '1.8', marginRight: '10px' }}
                    />
                    <i className='mdi mdi-settings' style={{ scale: '1.8' }} />
                  </div>
                  <Collapse in={this.state.userMenuOpen}>
                    <ul className='nav flex-column sub-menu'>
                      <li className='nav-item'>
                        {' '}
                        <Link
                          className={
                            this.isPathActive('/configuration/admin')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/admin'>
                          <span className='menu-subtitle'>
                            <Trans>Admin</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/configuration/list-role')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/list-role'>
                          <span className='menu-subtitle'>
                            <Trans>Roles</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/configuration/ratio')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/ratio'>
                          <span className='menu-subtitle'>
                            <Trans>Ratio</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/configuration/subsidy')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/subsidy'>
                          <span className='menu-subtitle'>
                            <Trans>Subsidy</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/configuration/log')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/log'>
                          <span className='menu-subtitle'>
                            <Trans>Logs</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/configuration/limit-order')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/limit-order'>
                          <span className='menu-subtitle'>
                            <Trans>Limit Order</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive('/configuration/component')
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/component'>
                          <span className='menu-subtitle'>
                            <Trans>Component</Trans>
                          </span>
                        </Link>
                        <Link
                          className={
                            this.isPathActive(
                              '/configuration/register/distributor-register'
                            )
                              ? 'nav-link active'
                              : 'nav-link'
                          }
                          to='/configuration/register/distributor-register'>
                          <span className='menu-subtitle'>
                            <Trans>Register</Trans>
                          </span>
                        </Link>
                        {/* <Link
                        className={
                          this.isPathActive('/configuration/list-user')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        to='/configuration/list-user'>
                        <span className='menu-subtitle'>
                          <Trans>User List</Trans>
                        </span>
                      </Link> */}
                      </li>
                    </ul>
                  </Collapse>
                </li>
              ) : (
                <></>
              )}

              {/* Logout */}
              <li className='nav-item'>
                <div
                  className='nav-link'
                  onClick={() =>
                    this.handleLogout(
                      localStorage.getItem('token'),
                      apiLogout,
                      '/login'
                    )
                  }>
                  <span className='menu-title'>
                    <Trans>Logout</Trans>
                  </span>
                  <i
                    className='mdi mdi-logout menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </div>
              </li>
            </>
          )}

          {isDistributor && (
            <>
              <li className='nav-item nav-profile'>
                <a href='/distributor/dashboard' className='nav-link'>
                  <div className='nav-profile-image'>
                    <img
                      src={require('../../assets/images/logo.png')}
                      alt='profile'
                    />
                    <span className='login-status online'></span>{' '}
                  </div>
                  <div className='nav-profile-text'>
                    <span className='font-weight-bold mb-2'>
                      <Trans>Distributor</Trans>
                    </span>
                    <span className='text-primary font-weight-bold'>
                      <Trans>{nameDistributor}</Trans>
                    </span>
                  </div>
                </a>
              </li>
              <li
                className={
                  this.isPathActive('/distributor/dashboard')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link className='nav-link' to='/distributor/dashboard'>
                  <span className='menu-title'>
                    <Trans>Dashboard</Trans>
                  </span>
                  <i
                    className='mdi mdi-home menu-icon'
                    style={{ scale: '1.8' }}></i>
                </Link>
              </li>
              <li
                className={
                  this.isPathActive('/distributor/order')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link className='nav-link' to='/distributor/order'>
                  <span className='menu-title'>
                    <Trans>List Order</Trans>
                  </span>
                  <i
                    className='mdi mdi-cart menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </Link>
              </li>
              <li
                className={
                  this.isPathActive('/list-product')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link className='nav-link' to='/list-product'>
                  <span className='menu-title'>
                    <Trans>List Product</Trans>
                  </span>
                  <i
                    className='mdi mdi-clipboard-text menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </Link>
              </li>
              {/* report */}
              <li
                className={
                  this.isPathActive('/report-distributor/report-order')
                    ? 'nav-item active'
                    : 'nav-item'
                }>
                <Link
                  className='nav-link'
                  to='/report-distributor/report-order'>
                  <span className='menu-title'>
                    <Trans>Report</Trans>
                  </span>
                  <i
                    className='mdi  mdi-chart-arc menu-icon'
                    style={{ scale: '1.8' }}></i>
                </Link>
              </li>
              <div
                className='border-bottom'
                style={{
                  margin: '0 1.25rem',
                }}
              />
              {/* setting distributor client */}
              <li
                className={
                  this.isPathActive('/setting') ? 'nav-item active' : 'nav-item'
                }>
                <div
                  className={
                    this.state.settingMenuOpen
                      ? 'nav-link menu-expanded'
                      : 'nav-link'
                  }
                  onClick={() => this.toggleMenuState('settingMenuOpen')}
                  data-toggle='collapse'>
                  <span className='menu-title'>
                    <Trans>Setting</Trans>
                  </span>
                  <i
                    className='menu-arrow'
                    style={{ scale: '1.8', marginRight: '10px' }}
                  />
                  <i className='mdi mdi-settings' style={{ scale: '1.8' }} />
                </div>
                <Collapse in={this.state.settingMenuOpen}>
                  <ul className='nav flex-column sub-menu'>
                    <li className='nav-item'>
                      {' '}
                      <Link
                        className={
                          this.isPathActive('/setting/client')
                            ? 'nav-link active'
                            : 'nav-link'
                        }
                        w
                        to='/setting/client'>
                        <span className='menu-subtitle'>
                          <Trans>User Client</Trans>
                        </span>
                      </Link>
                    </li>
                  </ul>
                </Collapse>
              </li>

              {/* Logout */}
              <li className='nav-item'>
                <div
                  className='nav-link'
                  onClick={() =>
                    this.handleLogout(
                      localStorage.getItem('tokenDistributor'),
                      apiDistributorLogout,
                      '/distributor/login'
                    )
                  }>
                  <span className='menu-title'>
                    <Trans>Logout</Trans>
                  </span>
                  <i
                    className='mdi mdi-logout menu-icon'
                    style={{ scale: '1.8' }}
                  />
                </div>
              </li>
            </>
          )}
        </ul>
      </nav>
    );
  }
}

export default withRouter(Sidebar);
