import React, { Suspense, lazy, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Spinner from '../app/shared/Spinner';
import TokenContext from './redux/TokenContext';
import ReportChart from './Admin_Page/report/report-chart/ReportChart';
// ! Auth Page
// Verify OTP
const VerifyOtp = lazy(() => import('./Auth/VerifyOtp'));
// forgot password
const ForgotPassword = lazy(() => import('./Auth/ForgotPassword'));

const Login = lazy(() => import('./Auth/Login'));
const LoginDistributor = lazy(() => import('./Auth/LoginDistributor'));
// const Register1 = lazy(() => import('./Auth/Register'));

// ! Admin Page
// Dashboard
const DashboardAdmin = lazy(() => import('./Admin_Page/dashboard/Dashboard'));
// Order
const Order = lazy(() => import('./Admin_Page/order/Order'));
const ShippingChange = lazy(() =>
  import('./Admin_Page/order/Components/Shipping')
);
// Katalog Product
const ListProduct = lazy(() =>
  import('./Admin_Page/catalogue-product/ListProduct')
);
// Customer
const ListDistributor = lazy(() =>
  import('./Admin_Page/customer/ListDistributor')
);
const ListOutlet = lazy(() => import('./Admin_Page/customer/ListOutlet'));
const AddOutlet = lazy(() => import('./Admin_Page/customer/AddOutlet'));
const DistributorLoginList = lazy(() =>
  import('./Admin_Page/customer/LoginList')
);
// Payment Configurations
const PaymentConfig = lazy(() =>
  import('./Admin_Page/payment/PaymentConfiguration')
);
// Manage Discount
const ManageDiscount = lazy(() =>
  import('./Admin_Page/manage-discount/ManageDiscount')
);
// Promo
const VoucherPromo = lazy(() =>
  import('./Admin_Page/promo/VoucherPromo/VoucherPromo')
);
const BannerPromo = lazy(() =>
  import('./Admin_Page/promo/BannerPromo/BannerPromo')
);
// Report
const ReportPO = lazy(() =>
  import('./Admin_Page/report/order-report/ReportPO')
);
const ChartPO = lazy(() =>
  import('./Admin_Page/report/report-chart/ReportChart')
);
// const ProductReport = lazy(())
// Configuration
const ListRole = lazy(() =>
  import('./Admin_Page/configuration/ListRole/ListRole')
);
const ListUser = lazy(() =>
  import('./Admin_Page/configuration/ListUser/ListUser')
);
const Log = lazy(() => import('./Admin_Page/configuration/Log/Log'));
const Ratio = lazy(() => import('./Admin_Page/configuration/Ratio/Ratio'));
const Subsidy = lazy(() =>
  import('./Admin_Page/configuration/Subsidy/Subsidy')
);
const LimitOrder = lazy(() =>
  import('./Admin_Page/configuration/LimitOrder/LimitOrder')
);
const Component = lazy(() =>
  import('./Admin_Page/configuration/Component/Component')
);
const Admin = lazy(() => import('./Admin_Page/configuration/Admin/Admin'));
const DistributorRegister = lazy(() =>
  import('./Admin_Page/configuration/Register/Distributor-Register')
);
const DistributorVerifyOTP = lazy(() =>
  import('./Admin_Page/configuration/Register/Distributor-VerifyOTP')
);
// Manage Account
// const ManageAccount = lazy(() => import('./manage-account/ManageAccount'));

// ! Distributor Page
// Distributor
const DashboardDistributor = lazy(() =>
  import('./Distributor_Page/distributor-dashboard/Distributor-dashboard')
);
const Distributor = lazy(() =>
  import('./Distributor_Page/distributor/Distributor')
);
const ListProductDistributor = lazy(() =>
  import('./Distributor_Page/ListProduct/ListProductDistributor')
);
const DistributorSetting = lazy(() =>
  import('./Distributor_Page/distributor-setting/Setting')
);
const DistributorClient = lazy(() =>
  import('./Distributor_Page/distributor-client/DistributorClient')
);
const DistributorReporting = lazy(() =>
  import('./Distributor_Page/report/distributor-report')
);
const NotFoundPage = lazy(() => import('./general-page/Error404'));

const AppRoutes = () => {
  const [token, setToken] = useState(null);

  return (
    <TokenContext.Provider value={{ token, setToken }}>
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path='/dashboard' component={DashboardAdmin} />
          <Route path='/order/list-purchase' component={Order} />
          <Route path='/order/shipping-change' component={ShippingChange} />
          <Route
            path='/catalogue-product/list-product'
            component={ListProduct}
          />
          <Route
            path='/customer/list-distributor'
            component={ListDistributor}
          />
          <Route
            path='/customer/list-login-distributor'
            component={DistributorLoginList}
          />
          <Route path='/customer/list-outlet' component={ListOutlet} />
          <Route path='/customer/add-outlet' component={AddOutlet} />
          <Route path='/payment-configuration' component={PaymentConfig} />
          <Route path='/manage-discount' component={ManageDiscount} />
          <Route path='/promo/voucher' component={VoucherPromo} />
          <Route path='/promo/banner' component={BannerPromo} />
          <Route path='/report/report-order' component={ReportPO} />
          {/* <Route path='/report/report-chart' component={ChartPO} /> */}
          <Route path='/configuration/list-role' component={ListRole} />
          {/* <Route path='/configuration/list-user' component={ListUser} /> */}
          <Route path='/configuration/log' component={Log} />
          <Route path='/configuration/ratio' component={Ratio} />
          <Route path='/configuration/subsidy' component={Subsidy} />
          <Route path='/configuration/limit-order' component={LimitOrder} />
          <Route path='/configuration/component' component={Component} />
          <Route path='/configuration/admin' component={Admin} />
          <Route
            path='/configuration/register/distributor-register'
            component={DistributorRegister}
          />
          <Route
            path='/configuration/register/distributor-verify'
            component={DistributorVerifyOTP}
          />
          {/* <Route path='/manage-account' component={ManageAccount} /> */}
          <Route path='/login' component={Login} />
          <Route path='/distributor/login' component={LoginDistributor} />
          <Route path='/forgot-password' component={ForgotPassword} />
          <Route path='/distributor/verify-otp' component={VerifyOtp} />
          <Route
            path='/distributor/dashboard'
            component={DashboardDistributor}
          />
          <Route path='/distributor/order' component={Distributor} />
          <Route path='/list-product' component={ListProductDistributor} />
          <Route path='/settings' component={DistributorSetting} />
          <Route path='/setting/client' component={DistributorClient} />
          <Route
            path='/report-distributor/report-order'
            component={DistributorReporting}
          />
          <Redirect to='/login' />
        </Switch>
      </Suspense>
    </TokenContext.Provider>
  );
};

export default AppRoutes;
