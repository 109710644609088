import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { Row, Col, Card, Spinner, Button } from 'react-bootstrap';
import { Bar, Pie } from 'react-chartjs-2';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { url, apiPostPurchase } from '../../../api/services';
import HttpStatusCodes from '../../../api/HttpStatus';
const { SearchBar } = Search;

const ReportChart = () => {
  // get token from localstorage
  const token = localStorage.getItem('token');
  const history = useHistory();
  // state
  const [dataPO, setDataPO] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDataPO = async () => {
    setLoading(true);
    try {
      const data = {
        limit: 20,
        offset: 0,
        filter_type: '',
        filter_value: '',
        status: '',
        confirmation: '1',
        approved: '',
        approved2: '',
        approved3: '',
        financestts: '',
        region: '',
      };

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${url + apiPostPurchase}`,
        headers: {
          'X-Auth-Token': token,
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(data),
      };

      const response = await axios.request(config);
      if (response.status === HttpStatusCodes.OK) {
        setDataPO(response.data.content.result);
        setLoading(false);
      }
    } catch (error) {
      handleUnauthorizedError(error);
    }
  };

  const handleUnauthorizedError = (error) => {
    if (error.response?.status === HttpStatusCodes.UNAUTHORIZED) {
      Swal.fire({
        icon: 'error',
        title: 'Unauthorized',
        text: 'You are not authorized to access this page.',
        allowOutsideClick: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          setTimeout(() => {
            history.push('/login'); // Use history.push for routing
          }, 3000);
        },
      });
    }
  };

  const columns = [
    {
      dataField: 'id',
      text: 'ID',
      sort: true,
    },
    {
      dataField: 'date',
      text: 'PO Date',
      sort: true,
    },
    { dataField: 'distributor', text: 'Distributor', sort: true },
    { dataField: 'shipping_type', text: 'Delivery', sort: true },
    { dataField: 'no', text: 'No. Po', sort: true },
    { dataField: 'shipping_date', text: 'Shipping Date', sort: true },
    { dataField: 'items', text: 'Item PO', sort: true },
    { dataField: 'trans_status', text: 'Status PO', sort: true },
    { dataField: 'region', text: 'Regional', sort: true },
    { dataField: 'cost', text: 'Additional Cost', sort: true },
    { dataField: 'amount', text: 'Amount Order', sort: true },
    { dataField: 'total', text: 'Total Summary', sort: true },
  ];

  const paginationOptions = {
    sizePerPage: 10, // number of records to show per page
    hideSizePerPage: false, // hide the option to change the number of records per page
    hidePageListOnlyOnePage: true, // hide the pagination bar if there is only one page
    totalItemsCount: 10,
  };
  const exportToExcel = () => {
    setLoading(true);

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Report PO');

    // Menambahkan header
    const headers = [
      'id',
      'date',
      'distributor',
      'shipping_type',
      'docno',
      'shipping_date',
      'items',
      'trans_status',
      'region',
      'cost',
      'amount',
      'total',
    ];
    const headerRow = worksheet.addRow(headers);

    // Menambahkan gaya pada header row
    headerRow.eachCell((cell) => {
      cell.font = { bold: true, color: { argb: 'FFFFFF' } }; // Warna font putih dan tebal
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '2F75B5' },
      }; // Warna latar belakang biru
    });

    // Menambahkan data
    dataPO.forEach((row) => {
      const rowData = headers.map((header) => row[header.toLowerCase()]);
      worksheet.addRow(rowData);
    });
    // Menyimpan workbook sebagai file Excel
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(blob, 'report-po.xlsx');
      setLoading(false);
    });
  };

  const dataBar = {
    labels: ['January', 'February', 'March', 'April', 'May'],
    datasets: [
      {
        label: 'Sales',
        data: [65, 59, 80, 81, 56],
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
      },
    ],
  };

  const dataPie = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
      {
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#2ECC71',
          '#8E44AD',
          '#FFA500',
        ],
        hoverBackgroundColor: [
          '#FF6384',
          '#36A2EB',
          '#FFCE56',
          '#2ECC71',
          '#8E44AD',
          '#FFA500',
        ],
      },
    ],
  };

  useEffect(() => {
    // Inisialisasi komponen DataTable dengan konfigurasi yang sudah didefinisikan

    fetchDataPO();
  }, []);
  return (
    <section className='mt-4'>
      <Row>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Bar Chart</Card.Title>
              <Bar data={dataBar} />
            </Card.Body>
          </Card>
        </Col>
        <Col lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Pie Chart</Card.Title>
              <Pie data={dataPie} />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className='mt-4'>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Data Table</Card.Title>
              {loading ? (
                // Menampilkan spinner jika loading = true
                <div
                  className='d-flex justify-content-center align-items-center'
                  style={{ height: '300px' }}>
                  <Spinner animation='border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <ToolkitProvider
                  keyField='id'
                  data={dataPO}
                  columns={columns}
                  search
                  exportCSV={{
                    fileName: 'report-po.csv',
                    ignoreHeader: false,
                    noAutoBOM: false,
                    exportAll: true,
                  }}>
                  {(props) => (
                    <div>
                      <div className='mb-4'>
                        <SearchBar {...props.searchProps} />
                        <Button
                          variant='danger'
                          className='ml-2'
                          onClick={props.searchProps.onClear}>
                          <i
                            className='mdi mdi-delete'
                            style={{ marginRight: '8px' }}></i>
                          Clear
                        </Button>
                        <Button
                          variant='success'
                          className='ml-2'
                          onClick={exportToExcel}>
                          <i
                            className='mdi mdi-file-excel'
                            style={{
                              marginRight: '8px',
                              transform: 'scale(1.5)',
                            }}
                          />
                          Export to Excel
                        </Button>
                      </div>

                      <BootstrapTable
                        {...props.baseProps}
                        striped
                        bordered
                        hover
                        pagination={paginationFactory(paginationOptions)}
                      />
                    </div>
                  )}
                </ToolkitProvider>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
};

export default ReportChart;
