import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import Footer from './shared/Footer';
import { withTranslation } from 'react-i18next';
import { AuthProvider } from './Auth/auth';
import * as serviceWorker from '../serviceWorker';

const App = ({ location, i18n }) => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);

  useEffect(() => {
    onRouteChanged();
  }, [location]);

  const onRouteChanged = () => {
    const { pathname } = location;
    // console.log('ROUTE CHANGED');

    // const body = document.querySelector('body');
    // if (pathname === '/layout/RtlLayout') {
    //   body.classList.add('rtl');
    //   i18n.changeLanguage('ar');
    // } else {
    //   body.classList.remove('rtl');
    //   i18n.changeLanguage('en');
    // }

    window.scrollTo(0, 0);

    const fullPageLayoutRoutes = [
      '/login',
      '/distributor/login',
      '/forgot-password',
      '/distributor/verify-otp',
    ];

    const isFullPageLayout = fullPageLayoutRoutes.includes(pathname);
    setIsFullPageLayout(isFullPageLayout);

    const pageBodyWrapper = document.querySelector('.page-body-wrapper');
    if (isFullPageLayout) {
      pageBodyWrapper.classList.add('full-page-wrapper');
    } else {
      pageBodyWrapper.classList.remove('full-page-wrapper');
    }
  };

  if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('../serviceWorker.js')
        .then((registration) => {
          console.log(
            'Service Worker registered with scope:',
            registration.scope
          );
        })
        .catch((error) => {
          console.error('Service Worker registration failed:', error);
        });
    });
  }

  serviceWorker.register();

  return (
    <div className='container-scroller'>
      {!isFullPageLayout && <Navbar name='ADMIN' />}
      <div className='container-fluid page-body-wrapper'>
        {!isFullPageLayout && (
          <Sidebar name='PT. Industri Nabati Lestari' role='Company Admin' />
        )}
        <div className='main-panel'>
          <div className='content-wrapper'>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </div>
          {!isFullPageLayout && <Footer />}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(App));
