import React, { Component } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { FaUserCog } from 'react-icons/fa';
import 'moment/locale/id'; // Import local configuration if you want to display time in Bahasa Indonesia
import Swal from 'sweetalert2';
import { Trans } from 'react-i18next';
import axios from 'axios';
import { withRouter } from 'react-router-dom';

import {
  url,
  apiGetDecodeTokenDistributor,
  apiDistributorLogout,
} from '../api/services';

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTime: moment().format('HH:mm:ss'),
      isAdmin: false,
      isDistributor: false,
    };
  }

  componentDidMount() {
    const tokenDistributor = localStorage.getItem('tokenDistributor');
    this._isMounted = true;

    // Set interval to update time every 1 minute
    this.interval = setInterval(() => {
      if (this._isMounted) {
        this.setState({
          currentTime: moment().format('HH:mm:ss'),
        });
      }
    }, 60000); // 1 minute in milliseconds

    if (tokenDistributor) {
      let configDistributor = {
        method: 'get',
        url: `${url + apiGetDecodeTokenDistributor}`,
        headers: {
          'X-Auth-Token': `${tokenDistributor}`,
        },
      };

      axios
        .request(configDistributor)
        .then((response) => {
          const decodedTokenDistributor = response.data.content;
          // if (decodedTokenDistributor.role === 'distributor') {
          this.setState({
            isDistributor: true,
            isAdmin: false,
            nameDistributor: decodedTokenDistributor.username,
          });
          // }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Clear interval on component unmount
  }

  toggleOffcanvas() {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  toggleRightSidebar() {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  handleLogout = (token, logoutEndpoint, redirectPath, history) => {
    if (!token) {
      // If token is missing, redirect to the login page
      history.push(redirectPath);
      return;
    }

    Swal.fire({
      title: 'Logout',
      text: 'Are You Sure Want to Logout?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#198ae3',
      cancelButtonColor: '#ff0000',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        const config = {
          method: 'get',
          url: `${url + logoutEndpoint}`,
          headers: {
            'Content-Type': 'application/json',
            'X-auth-token': `${token}`,
          },
        };

        axios
          .request(config)
          .then((response) => {
            // Remove token from localStorage
            localStorage.removeItem('token');

            // Redirect to the specified path
            history.push(redirectPath);
          })
          .catch((error) => {
            console.log(error);
            // Handle errors or other actions as needed
          });
      }
    });
  };

  render() {
    const { isDistributor } = this.state;
    return (
      <nav className='navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row'>
        <div className='text-center navbar-brand-wrapper d-flex align-items-center justify-content-center'>
          <Link className='navbar-brand brand-logo' to='#'>
            <img
              src={require('../../assets/images/logo.png')}
              alt='logo'
              style={{ width: '150px', height: '85px' }}
            />
          </Link>
          <Link className='navbar-brand brand-logo-mini' to='/'>
            <img
              src={require('../../assets/images/logo.png')}
              alt='logo'
              style={{ width: '50px', height: '50px' }}
            />
          </Link>
        </div>
        <div className='navbar-menu-wrapper d-flex align-items-stretch'>
          <button
            className='navbar-toggler navbar-toggler align-self-center'
            type='button'
            onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
            <span className='mdi mdi-menu'></span>
          </button>
          {/* Update time using Moment.js */}
          <ul className='navbar-nav navbar-nav-right'>
            {!isDistributor && (
              <>
                {/* <li className='nav-item'>
                  <Dropdown alignRight>
                    <Dropdown.Toggle className='nav-link count-indicator'>
                      <i className='mdi mdi-bell-outline'></i>
                      <span className='count-symbol bg-danger'></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdown-menu navbar-dropdown preview-list'>
                      <h6 className='p-3 mb-0'>
                        <Trans>Notifications</Trans>
                      </h6>
                      <div className='dropdown-divider'></div>
                      <Dropdown.Item
                        className='dropdown-item preview-item'
                        onClick={(evt) => evt.preventDefault()}>
                        <div className='preview-thumbnail'>
                          <div className='preview-icon bg-success'>
                            <i className='mdi mdi-calendar'></i>
                          </div>
                        </div>
                        <div className='preview-item-content d-flex align-items-start flex-column justify-content-center'>
                          <h6 className='preview-subject font-weight-normal mb-1'>
                            <Trans>Event today</Trans>
                          </h6>
                          <p className='text-gray ellipsis mb-0'>
                            <Trans>
                              Just a reminder that you have an event today
                            </Trans>
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className='dropdown-divider'></div>
                      <Dropdown.Item
                        className='dropdown-item preview-item'
                        onClick={(evt) => evt.preventDefault()}>
                        <div className='preview-thumbnail'>
                          <div className='preview-icon bg-warning'>
                            <i className='mdi mdi-settings'></i>
                          </div>
                        </div>
                        <div className='preview-item-content d-flex align-items-start flex-column justify-content-center'>
                          <h6 className='preview-subject font-weight-normal mb-1'>
                            <Trans>Settings</Trans>
                          </h6>
                          <p className='text-gray ellipsis mb-0'>
                            <Trans>Update dashboard</Trans>
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className='dropdown-divider'></div>
                      <Dropdown.Item
                        className='dropdown-item preview-item'
                        onClick={(evt) => evt.preventDefault()}>
                        <div className='preview-thumbnail'>
                          <div className='preview-icon bg-info'>
                            <i className='mdi mdi-link-variant'></i>
                          </div>
                        </div>
                        <div className='preview-item-content d-flex align-items-start flex-column justify-content-center'>
                          <h6 className='preview-subject font-weight-normal mb-1'>
                            <Trans>Launch Admin</Trans>
                          </h6>
                          <p className='text-gray ellipsis mb-0'>
                            <Trans>New admin wow</Trans>!
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className='dropdown-divider'></div>
                      <h6 className='p-3 mb-0 text-center cursor-pointer'>
                        <Trans>See all notifications</Trans>
                      </h6>
                    </Dropdown.Menu>
                  </Dropdown>
                </li> */}
                <li className='nav-item'>
                  <p className='font-weight-bold body-color'>
                    Update Terakhir tanggal {moment().format('DD-MM-YYYY')}{' '}
                    pukul {this.state.currentTime} WIB
                  </p>
                </li>
              </>
            )}

            {isDistributor && (
              <>
                {/* <li className='nav-item'>
                  <Dropdown alignRight>
                    <Dropdown.Toggle className='nav-link count-indicator'>
                      <i className='mdi mdi-bell-outline'></i>
                      <span className='count-symbol bg-danger'></span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdown-menu navbar-dropdown preview-list'>
                      <h6 className='p-3 mb-0'>
                        <Trans>Notifications</Trans>
                      </h6>
                      <div className='dropdown-divider'></div>
                      <Dropdown.Item
                        className='dropdown-item preview-item'
                        onClick={(evt) => evt.preventDefault()}>
                        <div className='preview-thumbnail'>
                          <div className='preview-icon bg-success'>
                            <i className='mdi mdi-calendar'></i>
                          </div>
                        </div>
                        <div className='preview-item-content d-flex align-items-start flex-column justify-content-center'>
                          <h6 className='preview-subject font-weight-normal mb-1'>
                            <Trans>Event today</Trans>
                          </h6>
                          <p className='text-gray ellipsis mb-0'>
                            <Trans>
                              Just a reminder that you have an event today
                            </Trans>
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className='dropdown-divider'></div>
                      <Dropdown.Item
                        className='dropdown-item preview-item'
                        onClick={(evt) => evt.preventDefault()}>
                        <div className='preview-thumbnail'>
                          <div className='preview-icon bg-warning'>
                            <i className='mdi mdi-settings'></i>
                          </div>
                        </div>
                        <div className='preview-item-content d-flex align-items-start flex-column justify-content-center'>
                          <h6 className='preview-subject font-weight-normal mb-1'>
                            <Trans>Settings</Trans>
                          </h6>
                          <p className='text-gray ellipsis mb-0'>
                            <Trans>Update dashboard</Trans>
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className='dropdown-divider'></div>
                      <Dropdown.Item
                        className='dropdown-item preview-item'
                        onClick={(evt) => evt.preventDefault()}>
                        <div className='preview-thumbnail'>
                          <div className='preview-icon bg-info'>
                            <i className='mdi mdi-link-variant'></i>
                          </div>
                        </div>
                        <div className='preview-item-content d-flex align-items-start flex-column justify-content-center'>
                          <h6 className='preview-subject font-weight-normal mb-1'>
                            <Trans>Launch Admin</Trans>
                          </h6>
                          <p className='text-gray ellipsis mb-0'>
                            <Trans>New admin wow</Trans>!
                          </p>
                        </div>
                      </Dropdown.Item>
                      <div className='dropdown-divider'></div>
                      <h6 className='p-3 mb-0 text-center cursor-pointer'>
                        <Trans>See all notifications</Trans>
                      </h6>
                    </Dropdown.Menu>
                  </Dropdown>
                </li> */}
                <li className='nav-item'>
                  <p className='font-weight-bold body-color'>
                    Update Terakhir tanggal {moment().format('DD-MM-YYYY')}{' '}
                    pukul {this.state.currentTime} WIB
                  </p>
                </li>
                <li className='nav-item nav-profile'>
                  <Dropdown alignRight>
                    <Dropdown.Toggle className='nav-link'>
                      <div className='nav-profile-img'>
                        <FaUserCog size={25} />
                      </div>
                      <div
                        className='nav-profile-text'
                        style={{ marginLeft: '1rem' }}>
                        <p className='mb-1 text-black'>Settings</p>
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className='navbar-dropdown'>
                      <Dropdown.Item>
                        <Link className='nav-link' to='/settings'>
                          <i className='mdi mdi-cached mr-2 text-success'></i>
                          <Trans>Profile</Trans>
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <div
                          className='nav-link'
                          onClick={() =>
                            this.handleLogout(
                              localStorage.getItem('tokenDistributor'),
                              apiDistributorLogout,
                              '/distributor/login',
                              this.props.history
                            )
                          }>
                          <i className='mdi mdi-logout mr-2 text-primary'></i>
                          <Trans>Sign out</Trans>
                        </div>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    );
  }
}

export default withRouter(Navbar);
